import React from 'react';

const Login = React.lazy(() => import('./App/pages/auth/login'));
const SignUp = React.lazy(() => import('./App/pages/auth/signup'));
const ResetPassword = React.lazy(() => import('./App/pages/auth/reset'));

const route = [
    { path: '/auth/login', exact: true, name: 'Login', component: Login },
    { path: '/auth/signup', exact: true, name: 'Signup', component: SignUp },
    { path: '/auth/reset-password', exact: true, name: 'ResetPassword', component: ResetPassword }
];

export default route;