export default {
    defaultPath: '/dashboard',
    basename: '/',
    layout: 'vertical',
    preLayout: null,
    collapseMenu: false,
    layoutType: 'menu-light',
    navIconColor: false,
    headerBackColor: 'header-default',
    navBackColor: 'navbar-default',
    navBrandColor: 'brand-default',
    navBackImage: false,
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: false,
    boxLayout: false,
    navDropdownIcon: 'style1',
    navListIcon: 'style1',
    navActiveListColor: 'active-default',
    navListTitleColor: 'title-default',
    navListTitleHide: false,
    configBlock: false,
}