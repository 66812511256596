import React from 'react';

const LoadingScreen = () => {
    return (
        <div className="d-flex align-items-center justify-content-center loading-screen">
            <div className="infinityChrome">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export default LoadingScreen;