import React from 'react'
import { withRouter } from 'react-router-dom'
import { Route, Redirect } from 'react-router'
import { connect } from 'react-redux'

const AuthRoute = ({
    component: Component,
    isAuthenticated,
    user,
    isLoading,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated && user ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/auth/login',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    )
}

const mapStateToProps = state => ({
    isLoading: state.isLoading,
    isAuthenticated: state.isAuthenticated,
    user: state.user
})

export default withRouter(connect(mapStateToProps)(AuthRoute))